import React from "react";

import { Link } from "react-router-dom";

function Footer(props) {
	return (
		<div>
			{/* <!--Footer Area--> */}
			<div className="container-fluid footer">
				<div className="row">
					<div className="container main-container-home">
						<div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
							<h3>Pages</h3>
							<ul className="list-group">
								<li>
									<Link to="/about-us">About us</Link>
								</li>

								<li>
									<Link to="/contact-us">Contact us</Link>
								</li>
								<li>
									<Link to="/">Employer</Link>
								</li>
							</ul>
						</div>
						{/* <!---Footer Column 01--> */}
						<div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
							<h3>Other page</h3>
							<ul className="list-group">
								<li>
									<Link to="/terms-and-conditions">Terms and conditions</Link>
								</li>
								<li>
									<Link to="/privacy-policy">Privacy Policy</Link>
								</li>
							</ul>
						</div>
						{/* <!---Footer Column 01--> */}
						<div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
							<h3>Contact with us</h3>
							<p>
								3rd floor of Yamakawa Building,<br /> 3-13-2 Minami Aoyama, Minato-ku , Tokyo 107-0062
							</p>
							<p>03-5312-5452</p>
							<p>info.humanstyling@gmail.com</p>
						</div>
						{/* <!---Footer Column 01--> */}
						<div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
							<h3>About us</h3>
							<p>
							Job Magic has an in-depth understanding of the Indian consumer internet domain.
							With years of experience in the domain, strong cash flow generation and a diversified business portfolio,
							it one of the very few profitable pure play internet companies in the country.
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* <!--Footer Area--> 
<!--Last Footer Area----> */}
			<div className="container-fluid footer last-footer ">
				<div className="row">
					<div className="container main-container">
						<div className="col-lg-9 col-md-3 col-sm-9 col-xs-6">
							<p className="copyright">
								Copyright © Human Styling Co., Ltd. All Rights Reserved.
							</p>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
							<ul className="list-group">
								<li>
									<a
										href="https://www.facebook.com/Job-Magic-108996098067652"
										target="_blank"
										rel="noreferrer"
									>
										<i className="fa fa-facebook"></i>
									</a>
								</li>
								<li>
									<a
										href="https://twitter.com/worldwide_hr_tr"
										target="_blank"
										rel="noreferrer"
									>
										<i className="fa fa-twitter"></i>
									</a>
								</li>
								<li>
									<a
										href="https://line.me/R/ti/p/M11Ae0FnIB"
										target="_blank"
										rel="noreferrer"
									>
										<i className="fa fa-comment" aria-hidden="true"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			{/* <!--Last Footer Area---->  */}
		</div>
	);
}

export default Footer;
