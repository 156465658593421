export const jp = {
	//navigation bar
	home: "家",
	about_us: "会社について",
	jobs: "仕事",
	contact_us: "お問い合わせ",
	//about us content
	about_us_content:
		"Job Magicは、インドの消費者向けインターネットドメインを深く理解しています。この分野での長年の経験、強力なキャッシュフローの創出、多様な事業ポートフォリオを備えたこの企業は、国内でも数少ない収益性の高い純粋なインターネット企業の1つです。 分類された求人オンラインビジネスから始めて、Job Magicは急速に成長し、多様化し、他の人が従うべきパイオニアとしてベンチマークを設定しました。革新性、創造性、経験豊富で才能のあるリーダーシップチーム、そして起業家精神の強い文化に後押しされて、今日、それは採用、結婚、不動産、教育および関連サービスにおける日本有数のオンライン分類会社です。",
	account: "アカウント",
	jobpost: "求人情報",
	dashboard: "ダッシュボード",
	employers: "雇用主",
	applicants: "応募者",
	job_posts: "求人情報",
	job_master_data: "ジョブマスターデータ",
	calendar: "カレンダー",
	profile: "プロファイル",
	logout: "ログアウト",
	language_switch: "言語スイッチ",
	welcome_back: "おかえりなさい",
	view_profile: "プロファイルの表示",
	active: "アクティブ",
	inactive: "非活性",
	verified: "検証済み",
	unverified: "未確認",
	counts: "カウント",
	total_employers: "総雇用者",
	total_job_posts: "求人の合計",
	total_applicants: "合計応募者",
	total_applied_jobs: "総応募求人",
	personal_information: "個人情報",
	id: "ID",
	"e-mail": "Eメール",
	add_employer: "雇用主を追加",
	add_applicant: "申請者を追加",
	add_job_post: "求人情報を追加する",
	add_master_data: "マスターデータを追加する",
	name: "名前",
	status: "スターテス",
	verification: "検証",
	actions: "行動",
	view_details: "詳細を見る",
	activate: "活性化",
	deactivate: "非アクティブ化",
	delete: "消去",
	employer_details: "雇用主の詳細",
	applicant_details: "応募者詳細",
	job_details: "仕事の詳細",
	employer_information: "雇用者情報",
	applicant_information: "応募者の情報",
	enter_employer_name: "雇用主名を入力してください",
	enter_employer_email: "雇用主のメールアドレスを入力してください",
	edit_employer: "雇用主を編集する",
	edit_applicant: "申請者の編集",
	edit_job: "ジョブの編集",
	edit_details: "詳細を編集する",
	description: "説明",
	contact: "コンタクト",
	address: "住所",
	zip: "郵便番号",

	date_of_birth: "生年月日",
	add: "追加",
	cancel: "キャンセル",
	save: "保存する",
	confirm: "確認",
	ok: "わかった",
	done: "終わり",
	submit: "参加する",
	edit: "編集",
	upload: "アップロード",
	download: "ダウンロード",
	not_available: "利用不可",
	loading: "読み込み中",
	account_settings: "アカウント設定",
	credentials: "資格情報",
	new_password: "新しいパスワード",
	confirm_new_password: "新しいパスワードを確認",
	job_form: "ジョブフォーム",
	employer: "雇用者",
	summary: "概要",
	skills: "スキル",
	skill: "スキル",
	date_added: "発行日",
	salary: "給料",
	responsibilities: "責任",
	add_responsibility: "責任を追加する",
	job_type: "職種",
	job_categories: "職種",
	job_category: "職種",
	min_salary: "最低給与",
	max_salary: "最大給与",
	experience_required: "必要な経験",
	hours_per_week: "週数時間",
	qualifications: "資格",
	qualification: "資格",
	job_locations: "仕事の場所",
	job_location: "仕事の場所",
	applicant_sites: "申請者サイト",
	applicant_site: "申請者サイト",
	publish_on: "に公開",
	select_employer: "雇用主を選択",
	enter_job_summary: "仕事の概要を入力してください",
	enter_job_description: "仕事の説明を入力してください",
	select_job_type: "ジョブタイプを選択します",
	select_job_category: "職種を選択",
	enter_minimum_salary: "最低賃金を入力してください",
	enter_maximum_salary: "最大給与を入力してください",
	enter_minimum_experience_required: "最低限必要な経験を入力してください",
	enter_hours_per_week: "週あたりの時間を入力してください",
	enter_skill: "スキルを入力してください",
	enter_qualification: "資格を入力してください",
	enter_job_location: "仕事の場所を入力してください",
	enter_applicant_site: "申請者のサイトを入力してください",
	job_data: "求人データ",
	data_type: "データ・タイプ",
	select_data_type: "データ型を選択",
	data_list: "データリスト",
	enter_data: "データを入力してください",
	add_more_data: "データを追加する",
	no_results_found: "結果が見つかりません",
	no_more_results_found: "これ以上の結果は見つかりませんでした !",
	applied_jobs: "適用された仕事",
	current_salary: "現在の給与",
	expected_salary: "希望給与",

	// ==== Alerts =================================================================
	//Eg. => "Are you sure you want to delete Rahul ?" ( here,  Rahul => %%entity%%)
	//Eg. =>  "Are you sure you want to add job ?" ( here,  job => %%entity%%)
	"confirm_update_%%entity%%": "%%entity%% を更新してもよろしいですか？",
	"confirm_delete_%%entity%%": "%%entity%% を削除してもよろしいですか？",
	"confirm_activate_%%entity%%":
		"よろしいですか、%%entity%% をアクティブにしますか？",
	"confirm_deactivate_%%entity%%":
		"よろしいですか、%%entity%% を非アクティブ化しますか？",
	"updating_%%entity%%": "%%entity%% を更新しています..。",
	"deleting_%%entity%%": "%%entity%% を削除しています..。",
	"adding_%%entity%%": "%%entity%% を追加しています..",
	"activating_%%entity%%": "%%entity%% をアクティブにする ...",
	"deactivating_%%entity%%": "%%entity%% の非アクティブ化 ...",
	"%%entity%%_updated": "%%entity%% 更新されました！",
	"%%entity%%_deleted": "%%entity%% が削除されました！",
	"%%entity%%_added": "%%entity%% が追加されました！",
	"%%entity%%_activated": "%%entity%% がアクティブになりました !",
	"%%entity%%_deactivated": "%%entity%% 非アクティブ化 !",
	//==============================================================================

	// profile related ======================
	//common start
	please_choose: "お選びください",
	yes: "はい",
	no: "いいえ",
	//common end
	//basic detais start
	basic_information: "基本情報",
	member_registration: "会員登録",
	edit_profile_page_heading: "簡単 ステップ で、〇〇〇〇〇に無料登録！",
	email_address: "メールア",
	password: "パスワード",
	first_name: "名",
	last_name: "苗字",
	first_katakana_name: "カタカナの名",
	last_katakana_name: "カタカナの苗字",
	birthday: "生年月日",
	sex: "性別",
	male: "男性",
	female: "女性",
	address_postal_code: "住所 郵便番号",
	prefecture: "都道府県",
	city: "都市",
	country: "国",
	nearest_station: "最寄り駅",
	route_name: "ルート名",
	station_name: "駅名",
	telephone_number: "電話番号",
	current_employment_status: "現在の就業状況",
	employment_status: "就業状況",
	full_time_employee: "正社員",
	contract_employee: "契約社員",
	dispatched_labor: "派遣社員",
	part_time_job: "アルバイト",
	Subcontracting: "業務委託",
	self_employed_fc_owner: "自営・FCオーナー",
	turnover_in: "離職中",
	other: "その他",
	search: "検索",

	//basic detais end
	//Work history / educational background start
	work_history_educational_background: "職歴 /学歴",
	experience_in_occupation: "職種経験の有無",
	number_of_working_companies: "就業社数",
	final_education: "最終学歴",
	junior_high_school: "中学",
	high_school: "高校",
	technical_college: "高専",
	vocational_school: "専門学校",
	junior_college: "短大",
	university: "大学",
	grad_student: "大学院",
	graduation: "卒業",
	student: "在学中",
	drop_out: "中退",
	experience: "経験",
	school_name: "学校名",
	please_enter_your_school_name: "学校名をご入力ください",
	graduation_date: "卒業年月",
	//Work history / educational background end

	//Qualifications / skills start
	qualifications_skills: "資格/スキル",
	pc_skills: "PCスキル",
	word: "Word",
	no_experience: "経験なし",
	word_op_one: "初心・・・wordを使用して、文書の作成、保存をしたことがある",
	word_op_two: "初級・・・基本的なビジネス文書の作成や編集、保存、印刷ができる",
	word_op_three:
		"中級・・・表作成や各種書式設定、簡単な図形・画像を用いた文書作成ができる",
	word_op_four:
		"上級・・・外部データ等を利用した文書作成や差し込み印刷ができる",
	excel: "Excel",
	excel_op_one: "初心・・・Excelを使用して、表を作成したことがある",
	excel_op_two: "初級・・・基本的な表作成・グラフ作成や計算式入力ができる",
	excel_op_three:
		"中級・・・IF関数やピボットテーブル、複合グラフの作成・データ集計ができる",
	excel_op_four:
		"上級・・・様々な関数の活用やマクロ・VBAを使ったデータの作成ができる",
	access: "Access",
	access_op_one:
		"初心・・・Accessを使用して、データの入力、抽出をしたことがある",
	access_op_two: "初級・・・入力・印刷とデータ抽出・並び替えなどができる",
	access_op_three:
		"中級・・・テーブル・フォーム・レポートの編集やクエリの作成ができる",
	access_op_four:
		"上級・・・マクロ・VBAを使ったデータベースの作成・活用ができる",
	language_skill: "語学スキル",
	english_conversation: "英語会話",
	english_con_op_one: "できない・ほぼできない",
	english_con_op_two: "あまり得意ではない",
	english_con_op_three: "電話の取次ぎ程度はできる",
	english_con_op_four: "日常会話程度はできる",
	english_con_op_five: "ビジネス会話レベルができる",
	english_reading_comprehension: "英語読解",
	english_read_op_one: "できない・ほぼできない",
	english_read_op_two: "あまり得意ではない",
	english_read_op_three: "簡単な文書の読解程度はできる",
	english_read_op_four: "ビジネス文書の読解ができる",
	english_read_op_five: "翻訳ができる",
	toeic: "TOEIC",
	toefl: "TOEFL",
	eiken: "英検",
	level_one: "1級",
	level_two: "2級",
	semi_second_grade: "準2級",
	level_three: "3級～",
	none: "なし",
	select: "選択する",
	never_received: "受けたことがない",
	other_languages: "その他語学",
	business_related_qualifications: "ビジネス関連資格",
	other_appeal_points: "その他アピールポイント",

	//Desired condition
	desired_condition: "希望条件",
	desired_occupation: "希望条件",
	desired_occu_op_one: "事務・オフィス系",
	desired_occu_op_two: "工場・軽作業・物流・土木系",
	desired_occu_op_three: "IT・エンジニア系",
	desired_occu_op_four: "販売・営業・飲食・教育系",
	desired_occu_op_five: "WEB・クリエイター系",
	desired_work_region: "希望勤務地",
	unit_price: "単価",
	hourly_wage: "時給",
	daily_salary: "日給",
	monthly_salary: "月給",
	annual_salary: "年俸",
	volume_system: "出来高制",
	desired_working_hours: "希望勤務時間",
	working_hours_op_one: "指定しない指定しない",
	working_hours_op_two: "3か月以上（長期）",
	working_hours_op_three: "1～3ヶ月",
	working_hours_op_four: "1ヶ月以内",
	working_hours_op_five: "10日以内",
	desired_work_start_date: "希望勤務開始日",
	desired_work_op_one: "いつからでも可",
	desired_work_op_two: "希望日あり",
	other_desired_conditions: "その他アピールポイント",
};
