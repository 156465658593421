import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { logout } from "../../../../Store/Actions/Auth";
import lang_en from "../../../../assets/images/us.jpg";
import lang_jp from "../../../../assets/images/japan.png";
import logo from "../../../../assets/images/logo.png";
import LoginRegisterModel from "../../../LoginRegisterModel";

import { changeLanguage } from "../../../../Store/Actions/App";
import { useTranslator } from "../../../../Hooks/useTranslator";
import { Link } from "react-router-dom";

function Navbar(props) {
	const [toggleNav, setToggleNav] = useState(false);
	const [loginRegister, setLoginRegister] = useState(false);

	const handleToggle = useCallback(() => {
		setToggleNav((prevState) => !prevState);
	}, []);

	//Model for login/register toogle
	const handleLoginRegisterToogle = useCallback(() => {
		setLoginRegister((prevState) => !prevState);
	}, []);
	const dispatch = useDispatch();
	const { push } = useHistory();
	const { username } = useSelector((store) => store.auth);

	const handleClose = useCallback(() => {
		//console.log("why");
		setToggleNav(false);
	}, []);

	const handleLogout = useCallback(() => {
		dispatch(logout());
		push("/");
	}, [dispatch, push]);

	const t = useTranslator();
	const { lang } = useSelector((store) => store.app);

	const handleLanguageChange = React.useCallback(
		() => dispatch(changeLanguage()),
		[dispatch]
	);

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<div className="top_header fixed-header">
				<nav className="navbar navbar-fixed-top">
					<div className="container">
						<div className="logo">
							{/* <a href="index.html">
								
							</a> */}
							<Link to="/">
								<img src={logo} alt="logo" />{" "}
							</Link>
						</div>
						{username ? (
							<>
								<div>
									<div className="logins">
										<div
											type="button"
											className="language"
											title={t("Language Switch")}
											onClick={handleLanguageChange}
										>
											<img
												className=""
												src={lang === "en" ? lang_jp : lang_en}
												alt="Header Language"
												height="16"
											/>
										</div>

										<div className="fl-right" onClick={handleToggle}>
											<div className="user-login">
												{username &&
													username
														.toUpperCase()
														.split(" ")
														.reduce(
															(acc, name, index) =>
																index < 2 ? acc + name[0] : acc,
															""
														)}
											</div>
											<div className="user-name">{username}</div>
										</div>
									</div>
								</div>

								<div
									className={`dropdown-menu dropdown-menu-right ${
										toggleNav ? "show" : ""
									}`}
								>
									<span className="btn dropdown-item" onClick={handleClose}>
										<NavLink to="/applicant/profile" className="dropdown-item">
											<i className="fa fa-user"></i> My Profile
										</NavLink>
									</span>
									<div className="dropdown-divider"></div>
									<span className="btn dropdown-item" onClick={handleClose}>
										<NavLink
											to="/applicant/my-applied-job"
											className="dropdown-item"
										>
											<i className="fa fa-file"></i> Applied Jobs
										</NavLink>
									</span>
									<div className="dropdown-divider"></div>

									<span
										className="btn dropdown-item text-danger"
										onClick={handleLogout}
									>
										<i className="fa fa-sign-out text-danger"></i> Logout
									</span>
								</div>
							</>
						) : (
							<>
								<div className="logins">
									{/* <span
										onClick={handleLoginRegisterToogle}
										className="label job-type partytime"
									>
										Login/Register
									</span> */}
									<NavLink to="/Login" className="login">
										<i className="fa fa-user"></i>
									</NavLink>

									<div
										type="button"
										className="language"
										title={t("Language Switch")}
										onClick={handleLanguageChange}
									>
										<img
											className=""
											src={lang === "en" ? lang_jp : lang_en}
											alt="Header Language"
											height="16"
										/>
									</div>
								</div>
								<LoginRegisterModel
									className="widget w1"
									open={loginRegister}
									onClose={handleLoginRegisterToogle}
									message=""
								/>
							</>
						)}

						{/*
                <!-- Brand and toggle get grouped for better mobile display -->
                */}
						<div className="navbar-header">
							<button
								type="button"
								onClick={handleToggle}
								className={`navbar-toggle ${toggleNav ? "collapsed" : ""}`}
								data-toggle="collapse"
								data-target="#bs-example-navbar-collapse-1"
								aria-expanded="false"
							>
								<span className="sr-only">Toggle navigation</span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
						</div>

						{/*
                <!-- Collect the nav links, forms, and other content for toggling -->
                */}
						<div
							className={`collapse navbar-collapse ${toggleNav ? "in" : ""}`}
							id="bs-example-navbar-collapse-1"
						>
							<ul className="nav navbar-nav">
								<li className="mobile-menu add-job"></li>
								<li className="" onClick={() => setToggleNav(false)}>
									<Link to="/">
										{t("home")} <span className="sr-only">(current)</span>
									</Link>
								</li>

								<li onClick={() => setToggleNav(false)}>
									<Link to="/about-us"> {t("about_us")}</Link>
								</li>

								<li onClick={() => setToggleNav(false)}>
									<Link to="/jobs">{t("jobs")}</Link>
								</li>
								<li onClick={() => setToggleNav(false)}>
									<Link to="/contact-us">{t("contact_us")}</Link>
								</li>
							</ul>
						</div>
						{/*
                <!-- navbar-collapse -->
                */}
					</div>
					{/*
            <!-- container-fluid -->
            */}
				</nav>
			</div>
		</ClickAwayListener>
	);
}

export default Navbar;
