import {
	AUTH_INIT,
	AUTH_FAIL,
	UNSET_LOADING,
	LOGIN_SUCCESS,
	LOGOUT,
	UPDATE_TOKEN,
} from "../../Actions/Auth";
import { SET_PROFILE_PIC, RESET_PROFILE_PIC } from "../../Actions/App";

import { UPDATE_ACCOUNT_SUCCESS } from "../../Actions/Account";

const initState = {
	id: null,
	isAdmin: false,
	username: null,
	token: null,
	email: null,
	isVerfied: null,
	error: null,
	loading: false,
	profileImage: null,
	isProfileUpdated: null,
};

const AuthReducer = (state = initState, action) => {
	const { type, data } = action;
	switch (type) {
		// Authentication
		case AUTH_INIT:
			return { ...state, loading: true };
		case LOGIN_SUCCESS:
			return {
				...state,
				isAdmin: data.role.toLowerCase() === "applicant",
				username: data.name,
				email: data.email,
				token: data.jwtToken,
				id: data.id,
				loading: false,
				isProfileUpdated: data.isProfileUpdated,
			};
		case AUTH_FAIL:
			return {
				...state,
				error: data ? data.message : "Oops, something went wrong !!",
				loading: false,
			};
		case LOGOUT:
			return {
				...initState,
			};
		case UPDATE_TOKEN:
			return { ...state, token: data };

		//Update Profile
		case UPDATE_ACCOUNT_SUCCESS: {
			if (parseInt(data.id) === parseInt(state.id)) {
				localStorage.setItem("name", data.applicantDetail.firstName);
				return { ...state, username: data.applicantDetail.firstName };
			}
			return state;
		}

		//Profile Image
		case SET_PROFILE_PIC:
			return { ...state, profileImage: data };
		case RESET_PROFILE_PIC:
			return { ...state, profileImage: null };

		// Unset Loading
		case UNSET_LOADING:
			return { ...state, loading: false };

		default:
			return state;
	}
};

export default AuthReducer;
