import {
	GET_JOBS_INIT,
	GET_JOBS_SUCCESS,
	GET_JOBS_FAIL,
	GET_JOB_DETAILS_INIT,
	GET_JOB_DETAILS_SUCCESS,
	GET_JOB_DETAILS_FAIL,
	GET_JOB_META_SUCCESS,
	APPLY_JOB_SUCCESS,
	APPLY_JOB_FAIL,
	APPLY_JOB_INIT,
	GET_APPLIED_JOB_INIT,
	GET_APPLIED_JOB_SUCCESS,
	GET_APPLIED_JOB_FAIL,
} from "../../Actions/Job";

const initState = {
	jobList: {
		list: [],
		loading: false,
		pageNo: 0,
		size: 70,
	},
	jobMetaData: {
		jobTypes: {
			id: -1,
			displayString: "Job Types",
			singular: "Type",
			data: [],
		},
		skills: { id: 0, displayString: "Skills", singular: "Skill", data: [] },
		qualifications: {
			id: 2,
			displayString: "Qualifications",
			singular: "Qualification",
			data: [],
		},
		// applicantSites: {
		// 	id: 4,
		// 	displayString: "Applicant Sites",
		// 	singular: "Site",
		// 	data: [],
		// },
		jobCategories: {
			id: 1,
			displayString: "Job Categories",
			singular: "Category",
			data: [],
		},
		jobLocations: {
			id: 3,
			displayString: "Job Locations",
			singular: "Location",
			data: [],
		},
	},

	jobDetails: "loading",
	jobApplyRes: {
		message: "",
		loading: false,
	},
	appliedJobs: "loading",
	appliedJobList: {
		list: [],
		loading: false,
		pageNo: 0,
		size: 70,
	},
};

const JobReducer = (state = initState, action) => {
	const { type, data } = action;
	switch (type) {
		// Jobs
		case GET_JOBS_INIT:
			return {
				...state,
				jobList: { ...state.jobList, loading: true, list: [] },
			};
		case GET_JOBS_SUCCESS: {
			const updatedList = [...state.jobList.list, ...data];
			const nextPage = state.jobList.atPage + 1;
			return {
				...state,
				jobList: {
					...state.jobList,
					list: updatedList,
					loading: false,
					atPage: nextPage,
				},
			};
		}
		case GET_JOBS_FAIL:
			return { ...state, jobList: { ...state.jobList, loading: false } };

		//Job details
		case GET_JOB_DETAILS_INIT:
			return { ...state, jobDetails: "loading" };
		case GET_JOB_DETAILS_SUCCESS:
			return { ...state, jobDetails: data };
		case GET_JOB_DETAILS_FAIL:
			return { ...state, jobDetails: null };
		// Job meta data
		case GET_JOB_META_SUCCESS: {
			let updatedMeta = { ...state.jobMetaData };
			if (data.jobTypes) updatedMeta.jobTypes.data = data.jobTypes;
			if (data.skills) updatedMeta.skills.data = data.skills;
			if (data.qualifications)
				updatedMeta.qualifications.data = data.qualifications;
			// if (data.applicantSites)
			// 	updatedMeta.applicantSites.data = data.applicantSites;
			if (data.jobCategories)
				updatedMeta.jobCategories.data = data.jobCategories;
			if (data.jobLocations) updatedMeta.jobLocations.data = data.jobLocations;
			return { ...state, jobMetaData: updatedMeta };
		}
		//apply job
		case APPLY_JOB_SUCCESS: {
			return {
				...state,
				jobApplyRes: { message: action.payload, loading: false },
			};
		}
		case APPLY_JOB_FAIL: {
			return {
				...state,
				jobApplyRes: { message: action.payload, loading: false },
			};
		}
		case APPLY_JOB_INIT: {
			return {
				...state,
				jobApplyRes: { ...state.jobApplyRes, loading: true },
			};
		}
		//Job details
		case GET_APPLIED_JOB_INIT:
			return {
				...state,
				appliedJobList: { ...state.appliedJobList, loading: true, list: [] },
			};
		case GET_APPLIED_JOB_SUCCESS:
			const updatedList = [...state.appliedJobList.list, ...data];
			const nextPage = state.appliedJobList.atPage + 1;
			return {
				...state,
				appliedJobList: {
					...state.appliedJobList,
					list: updatedList,
					loading: false,
					atPage: nextPage,
				},
			};
		case GET_APPLIED_JOB_FAIL:
			return {
				...state,
				appliedJobList: { ...state.appliedJobList, loading: false },
			};

		default:
			return state;
	}
};

export default JobReducer;
