import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Landing from "../Containers/Landing";
const Home = React.lazy(() => import("./Home"));
const AboutUs = React.lazy(() => import("./AboutsUs"));
const PrivacyPolicy = React.lazy(() => import("./PrivacyPolicy"));
const TermsConditions = React.lazy(() => import("./TermsConditions"));
const JobPosts = React.lazy(() => import("./JobPosts"));
const Login = React.lazy(() => import("./Login"));
const Account = React.lazy(() => import("./Account"));
const Applicant = React.lazy(() => import("./Applicants"));
const ContactUs = React.lazy(() => import("./ContactUs"));

function AppRoutes() {
	const { token, isProfileUpdated } = useSelector((store) => store.auth);

	// if (isProfileUpdated === false)
	// 	return (
	// 		<Suspense fallback={<div></div>}>
	// 			<Switch>
	// 				<Redirect from="/" to="/applicant/edit-applicant-details" />
	// 			</Switch>
	// 		</Suspense>
	// 	);
	// else
	return (
		<Landing>
			<Suspense fallback={<div></div>}>
				<Switch>
					{!token && <Route path="/login" component={Login} />}
					<Route path="/account" component={Account} />
					<Route path="/" exact component={Home} />
					<Route path="/about-us" exact component={AboutUs} />
					<Route path="/privacy-policy" exact component={PrivacyPolicy} />
					<Route path="/applicant" component={Applicant} />
					<Route
						path="/terms-and-conditions"
						exact
						component={TermsConditions}
					/>
					<Route path="/jobs" component={JobPosts} />
					<Route path="/contact-us" component={ContactUs} />
					{/* {isAdmin && <Route path="/Employers" component={Employers} />}
            <Route path="/JobPosts" component={JobPosts} />
            <Route path="/Applicants" component={Applicants} />
            <Route path="/Calendar" component={Calendar} />
            <Route path="/Profile" component={Profile} /> */}
					{isProfileUpdated === false && (
						<Redirect from="/" to="/applicant/edit-applicant-details" />
					)}
					<Redirect from="/" to="/" />
				</Switch>
			</Suspense>
		</Landing>
	);
}

export default AppRoutes;
