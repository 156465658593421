import {
  GET_ACCOUNT_DETAILS_INIT,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_ACCOUNT_DETAILS_FAIL,
  GET_ACCOUNTS_INIT,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAIL,
  UPDATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT,
  DEACTIVATE_ACCOUNT,
  ADD_ACCOUNT,
  DELETE_ACCOUNT,
  UPDATE_RESUME_SUCCESS,
  accountTypes,
} from "../../Actions/Account";
import { LOGOUT } from "../../Actions/Auth";

const initState = {
  accountDetails: "loading",
  employers: {
    list: [],
    loading: false,
    error: null,
  },
  applicants: {
    list: [],
    loading: false,
    error: null,
  },
};

const AppReducer = (state = initState, action) => {
  const { type, data } = action;
  let accountKey;
  if (data && data.accountType)
    accountKey =
      data.accountType === accountTypes.applicant ? "applicants" : "";
  switch (type) {
    // Account details
    case GET_ACCOUNT_DETAILS_INIT:
      return { ...state, accountDetails: "loading" };
    case GET_ACCOUNT_DETAILS_SUCCESS:
      return { ...state, accountDetails: data };
    case GET_ACCOUNT_DETAILS_FAIL:
      return { ...state, accountDetails: null };

    //Update Account
    case UPDATE_ACCOUNT_SUCCESS:
      return { ...state, accountDetails: { ...state.accountDetails, ...data } };

    // Get Accounts
    case GET_ACCOUNTS_INIT:
      return {
        ...state,
        [accountKey]: {
          ...state[accountKey],
          loading: true,
          list: [],
          error: null,
        },
      };

    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        [accountKey]: { list: data.response, loading: false },
      };
    case GET_ACCOUNTS_FAIL:
      return {
        ...state,
        [accountKey]: { loading: false, error: data.error.message },
      };

    // Activate Account
    case ACTIVATE_ACCOUNT: {
      const id = data.id;
      let updatedAccounts = [...state[accountKey].list];
      let updateIndex = updatedAccounts.findIndex(
        (account) => parseInt(id) === parseInt(account.id)
      );
      if (updateIndex >= 0) {
        updatedAccounts[updateIndex].approved = true;
        return { ...state, [accountKey]: { list: updatedAccounts } };
      } else return state;
    }

    // Deactivate Account
    case DEACTIVATE_ACCOUNT: {
      const id = data.id;
      let updatedAccounts = [...state[accountKey].list];
      let updateIndex = updatedAccounts.findIndex(
        (account) => parseInt(id) === parseInt(account.id)
      );
      if (updateIndex >= 0) {
        updatedAccounts[updateIndex].approved = false;
        return { ...state, [accountKey]: { list: updatedAccounts } };
      } else return state;
    }

    // Delete Account
    case DELETE_ACCOUNT: {
      const id = data.id;
      let updatedAccounts = [...state[accountKey].list];
      let updateIndex = updatedAccounts.findIndex(
        (account) => parseInt(id) === parseInt(account.id)
      );
      if (updateIndex >= 0) {
        updatedAccounts.splice(updateIndex, 1);
        return { ...state, [accountKey]: { list: updatedAccounts } };
      } else return state;
    }

    // Add Account
    case ADD_ACCOUNT: {
      let updatedAccounts = [...state[accountKey].list];
      updatedAccounts.push(data.response);
      return { ...state, [accountKey]: { list: updatedAccounts } };
    }

    //Resume Update
    case UPDATE_RESUME_SUCCESS:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          applicantDetail: {
            ...state.accountDetails.applicantDetail,
            resumeUri: data,
          },
        },
      };

    // Logout
    case LOGOUT:
      return { ...initState };

    default:
      return state;
  }
};

export default AppReducer;
