import React from "react";
//import { useWindowSize } from "../../Hooks/useWindowSize";
import Header from "./Header";
import Footer from "./footer";
import Alert from "../../Components/Common/Alert";

function Landing(props) {
	const { children } = props;
	//const { width } = useWindowSize();
	//const [sidebarOpen, setsidebarOpen] = useState(window.innerWidth >= 992);

	// useEffect(() => {
	// 	if (width < 992) setsidebarOpen(false);
	// 	else setsidebarOpen(true);
	// }, [width]);

	// const handleSidebarToogle = useCallback(() => {
	// 	setsidebarOpen((prevState) => !prevState);
	// }, []);

	// const handleSidebarClose = useCallback(() => {
	// 	setsidebarOpen(false);
	// }, []);

	return (
		<div id="layout-wrapper">
			<Header />
			{children}
			<Footer />

			<Alert />
		</div>
	);
}

export default Landing;
