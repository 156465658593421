import { Api } from "../Api";
import { setAlert, unsetAlert } from "../App";

// Auth
export const AUTH_INIT = "AUTH_INIT";
export const AUTH_FAIL = "AUTH_FAIL";
const onAuthInit = () => ({
	type: AUTH_INIT,
});
const onAuthFail = (response) => ({
	type: AUTH_FAIL,
	data: response,
});

// Login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const login = (userData) =>
	Api({
		url: "/accounts/authenticate",
		data: userData,
		method: "POST",
		onInit: onAuthInit,
		onSuccess: onLoginSuccess,
		onFailure: onAuthFail,
	});
const onLoginSuccess = (response) => {
	localStorage.setItem("username", response.name);
	localStorage.setItem("token", response.jwtToken);
	localStorage.setItem("email", response.email);
	localStorage.setItem("role", response.role);
	localStorage.setItem("id", response.id);
	localStorage.setItem("isProfileUpdated", response.isProfileUpdated);
	return {
		type: LOGIN_SUCCESS,
		data: response,
	};
};

//Auth Check
export const AUTH_CHECK_FAIL = "AUTH_CHECK_FAIL";
export const checkAuth = () => {
	let id = localStorage.getItem("id");
	if (id) {
		let role = localStorage.getItem("role");
		let name = localStorage.getItem("name");
		let email = localStorage.getItem("email");
		let jwtToken = localStorage.getItem("token");
		let isProfileUpdated = localStorage.getItem("isProfileUpdated");
		return {
			type: LOGIN_SUCCESS,
			data: {
				name,
				role,
				email,
				jwtToken,
				id,
				isProfileUpdated,
			},
			isProfileUpdated,
		};
	}
	return { type: AUTH_CHECK_FAIL };
};

// Logout
export const LOGOUT = "LOGOUT";
export const logout = () =>
	Api({
		url: "/Accounts/revoke-token",
		method: "POST",
		onInit: onLogout,
		data: {},
	});
const onLogout = () => {
	localStorage.clear("name");
	localStorage.clear("email");
	localStorage.clear("token");
	localStorage.clear("role");
	localStorage.clear("id");
	return {
		type: LOGOUT,
	};
};

// Forgot Password
export const forgotPassword = (data) =>
	Api({
		url: "/Accounts/forgot-password",
		data: data,
		method: "POST",
		onInit: onAuthInit,
		onSuccess: onSuccessSetAlert,
		onFailure: onAuthFail,
	});

// Register
export const register = (data) =>
	Api({
		url: "/Accounts/register",
		data: data,
		method: "POST",
		onInit: onAuthInit,
		onSuccess: onSuccessSetAlert,
		onFailure: onAuthFail,
	});

//Reset Password
export const resetPassword = (data) =>
	Api({
		url: "/Accounts/reset-password",
		data: data,
		method: "POST",
		onInit: onAuthInit,
		onFailure: onAuthFail,
	});

export const UNSET_LOADING = "UNSET_LOADING";
const onSuccessSetAlert = (response) => (dispatch) => {
	dispatch({
		type: UNSET_LOADING,
	});
	dispatch(
		setAlert({
			type: "success",
			message: response.message,
			onOk: () => dispatch(unsetAlert()),
		})
	);
};

//Verify Email
export const verifyEmail = (data) =>
	Api({
		url: "/Accounts/verify-email",
		data: data,
		method: "POST",
		onInit: onAuthInit,
		onFailure: onAuthFail,
	});

// Refresh JWT token
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const refreshJWT = (prevAction) => (dispatch) => {
	if (!window.refreshingToken) {
		window.refreshingToken = true;
		dispatch(
			Api({
				url: "/Accounts/refresh-token",
				method: "POST",
				onSuccess: onRefreshSuccess(prevAction),
				onFailure: onLogout,
			})
		);
	} else {
		setTimeout(() => dispatch(prevAction), 500);
	}
};
const onRefreshSuccess = (prevAction) => (response) => {
	localStorage.setItem("token", response.jwtToken);
	window.refreshingToken = false;
	return { ...prevAction, onInit: updateToken(response.jwtToken) };
};
const updateToken = (token) => () => ({ type: UPDATE_TOKEN, data: token });
