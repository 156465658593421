import { GET_EMPLOYER_DATA_SUCCESS } from "../../Actions/Home";

const initState = {
	empDetails: {
		loading: false,
		list: [],
	},
	employersDetails: "loading",
};

const HomeReducer = (state = initState, action) => {
	const { type, data } = action;
	switch (type) {
		//employer data
		case GET_EMPLOYER_DATA_SUCCESS:
			return {
				...state,
				empDetails: {
					...state.jobList,
					list: data,
					loading: false,
				},
			};

		default:
			return state;
	}
};

export default HomeReducer;
