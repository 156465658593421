import { dictionary, keyWords } from "./dictionary";
import { useSelector } from "react-redux";
import { useCallback } from "react";

export const useTranslator = () => {
  const { lang } = useSelector((store) => store.app);
  const translate = useCallback(
    (phrase) => {
      const phraseKey = phrase.toLowerCase().replaceAll(" ", "_").trim();
      if (!phraseKey.match(/.*%%.*%%.*/))
        return dictionary[lang][phraseKey]
          ? dictionary[lang][phraseKey]
          : phrase;
      else {
        const entity = phrase.split("%%")[1];
        // console.log(phraseKey);
        // console.log(entity);
        // console.log(phraseKey.replace(/%%.*%%/, "%%entity%%"));
        // console.log(
        //   dictionary[lang][phraseKey.replace(/%%.*%%/, "%%entity%%")]
        // );
        return (
          dictionary[lang][phraseKey.replace(/%%.*%%/, "%%entity%%")].replace(
            "%%entity%%",
            keyWords.includes(entity)
              ? dictionary[lang][
                  keyWords[keyWords.indexOf(entity)].toLowerCase()
                ]
              : entity
          ) || phrase
        );
      }
    },
    [lang]
  );
  return translate;
};
