import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateInput } from "../../Utils/InputValidator";
import { login, forgotPassword, register } from "../../Store/Actions/Auth";

import { Link } from "react-router-dom";

function Login(props) {
	const { error, loading } = useSelector((store) => store.auth);
	//const { onClose } = props;

	const [gender, setGender] = useState("");
	const [dob, setDob] = useState("");

	const dispatch = useDispatch();

	const defaultInputs = useMemo(
		() => [
			{
				id: "first-name",
				element: "input",
				type: "text",
				value: "",
				placeholder: "Enter your first Name",
				label: "First Name",
				validations: [{ rule: "notNull" }],
				valid: false,
				error: false,
				show: false,
			},
			{
				id: "last-name",
				element: "input",
				type: "text",
				value: "",
				placeholder: "Enter your last Name",
				label: "Last Name",
				validations: [{ rule: "notNull" }],
				valid: false,
				error: false,
				show: false,
			},
			{
				id: "first-katakana-name",
				element: "input",
				type: "text",
				value: "",
				placeholder: "Enter your first katakana name",
				label: "First Katakana Name",
				validations: [{ rule: "notNull" }],
				valid: false,
				error: false,
				show: false,
			},
			{
				id: "flast-katakana-name",
				element: "input",
				type: "text",
				value: "",
				placeholder: "Enter your last katakana name",
				label: "Last Katakana Name",
				validations: [{ rule: "notNull" }],
				valid: false,
				error: false,
				show: false,
			},
			{
				id: "user-email",
				element: "input",
				type: "text",
				value: "",
				placeholder: "Enter your email",
				label: "Email",
				validations: [{ rule: "notNull" }, { rule: "email" }],
				valid: false,
				error: false,
				show: true,
			},
			{
				id: "user-password",
				element: "input",
				type: "password",
				value: "",
				placeholder: "Enter password",
				label: "Password",
				validations: [
					{ rule: "notNull" },
					{ rule: "password" },
					{ rule: "minLength", length: 6 },
				],
				valid: false,
				error: false,
				show: true,
			},
		],
		[]
	);

	const [inputs, setinputs] = useState(defaultInputs);

	const [isPageValid, setisPageValid] = useState(false);
	const [isRegister, setisRegister] = useState(false);
	const [isResetPassword, setisResetPassword] = useState(false);

	const handleInputChange = React.useCallback(
		(event) => {
			const newValue = event.target.value;
			const inputIndex = inputs.findIndex(
				(input) => input.id === event.target.id
			);
			const newInput = { ...inputs[inputIndex] };
			newInput.value = newValue;

			if (newInput.validations) {
				for (let rule = 0; rule < newInput.validations.length; rule++) {
					newInput.error = validateInput(newValue, newInput.validations[rule]);
					if (newInput.error) {
						newInput.valid = false;
						break;
					} else {
						newInput.valid = true;
					}
				}
			}
			let newInputs = [...inputs];
			newInputs[inputIndex] = newInput;
			let status = true;
			for (let i = 0; i < newInputs.length; i++) {
				if (newInputs[i].show) {
					status = status && newInputs[i].valid;
				}
			}
			setisPageValid(status);
			setinputs(newInputs);
		},
		[inputs, setisPageValid, setinputs]
	);

	const loginToogleHandler = React.useCallback(
		(event) => {
			if (isResetPassword) setisResetPassword(false);
			else setisRegister((prevState) => !prevState);
		},
		[isResetPassword]
	);

	useEffect(() => {
		let updatedInputs = [...defaultInputs];
		if (isResetPassword) {
			updatedInputs[0].show = false;
			updatedInputs[2].show = false;
		} else {
			updatedInputs[2].show = true;
		}
		setinputs(updatedInputs);
	}, [isResetPassword, defaultInputs]);

	useEffect(() => {
		let updatedInputs = [...defaultInputs];

		if (isRegister) {
			updatedInputs[0].show = true;
			updatedInputs[1].show = true;
			updatedInputs[2].show = true;
			updatedInputs[3].show = true;
		} else {
			updatedInputs[0].show = false;
			updatedInputs[1].show = false;
			updatedInputs[2].show = false;
			updatedInputs[3].show = false;
		}
		setinputs(updatedInputs);
	}, [isRegister, defaultInputs]);

	const handleCloseAndReset = useCallback(() => {
		//onClose();
		setinputs([...defaultInputs]);
		setisRegister(false);
		setisResetPassword(false);
	}, [defaultInputs]);

	const handleSubmit = useCallback(
		(event) => {
			event.preventDefault();
			let reqBody;
			if (!isRegister && !isResetPassword) {
				reqBody = {
					email: inputs[4].value,
					password: inputs[5].value,
					isApplicant: true,
				};
				return dispatch(login(reqBody));
			} else if (isResetPassword) {
				reqBody = {
					email: inputs[1].value,
					isApplicant: true,
				};
				return dispatch(forgotPassword(reqBody)).then((res) => {
					handleCloseAndReset();
				});
			} else if (isRegister) {
				reqBody = {
					applicantDetail: {
						firstName: inputs[0].value,
						lastName: inputs[1].value,
						katakanaFirstName: inputs[2].value,
						katakanaLastName: inputs[3].value,
						gender: gender,
						dateOfBirth: dob,
					},

					// name: inputs[0].value,
					email: inputs[4].value,
					password: inputs[5].value,
					confirmPassword: inputs[5].value,

					role: "Applicant",
					acceptTerms: true,
					applicantSite: "jobmagic-qa.royaltechsoft.com",
				};
				return dispatch(register(reqBody)).then(() => {
					handleCloseAndReset();
				});
			}
		},
		[
			isRegister,
			isResetPassword,
			inputs,
			dispatch,
			handleCloseAndReset,
			gender,
			dob,
		]
	);

	const handleKeyPress = useCallback(
		(event) => {
			if (
				(event.which === 13 || event.keycode === 13) &&
				isPageValid &&
				event.target.id === "el3"
			)
				handleSubmit();
		},
		[isPageValid, handleSubmit]
	);

	return (
		<div className="container-fluid login_register header_area deximJobs_tabs">
			<div className="row">
				<div className="container main-container-home">
					<div className="col-lg-offset-1 col-lg-11 col-md-12 col-sm-12 col-xs-12">
						<div className="text-primary p-4">
							<h2 className="text-primary mb-0">
								<i className="mdi mdi-lock  mr-2"></i>
								{isRegister
									? "Register"
									: isResetPassword
									? "Reset Password"
									: `Login `}
							</h2>
						</div>
						<div className="tab-content">
							<div
								id="register-account"
								className="tab-pane fade in active white-text"
							>
								<div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 zero-padding-left">
									<div className="">
										<div className="justify-content-center mt-lg-4">
											<div className="">
												<div className="card overflow-hidden">
													<div className="bg-soft-primary">
														<div className="">
															<div className=""></div>
														</div>
													</div>
													<div className="card-body ">
														<div className="p-2">
															{error && (
																<div className="submit-error">{error}</div>
															)}
															<form className="contact_us">
																{inputs.map(
																	(input) =>
																		input.show && (
																			<div
																				className="form-group"
																				key={input.id}
																			>
																				<label htmlFor="username">
																					{input.label}
																				</label>

																				<input
																					type={input.type}
																					className={`form-control ${
																						input.error ? "is-invalid" : ""
																					}`}
																					id={input.id}
																					value={input.value}
																					placeholder={input.placeholder}
																					onChange={handleInputChange}
																					onKeyPress={handleKeyPress}
																					disabled={loading}
																				/>
																				<div className="invalid-feedback">
																					{input.error}
																				</div>
																			</div>
																		)
																)}
																{isRegister && (
																	<>
																		<div
																			className="form-group"
																			onChange={(e) =>
																				setGender(e.target.value)
																			}
																		>
																			<label>Sex</label>
																			<span className="radio-inline ">
																				<input
																					type="radio"
																					value="Male"
																					name="sex"
																				/>
																				Male
																			</span>
																			<span className="radio-inline">
																				<input
																					type="radio"
																					value="Female"
																					name="sex"
																				/>
																				Female
																			</span>
																		</div>
																		<div className="form-group">
																			<label>Date of Birth</label>

																			<input
																				type="date"
																				name="bday"
																				value={dob}
																				onChange={(e) => setDob(e.target.value)}
																				className="control"
																			/>
																		</div>
																	</>
																)}

																<div
																	className="mt-3"
																	style={{ textAlign: "center" }}
																>
																	<button
																		className="btn btn-print bg-blue btn-submit"
																		onClick={handleSubmit}
																		disabled={!isPageValid || loading}
																	>
																		{/* {loading && (
																			<>
																				<span
																					className="spinner-grow spinner-grow-sm mr-2"
																					role="status"
																					aria-hidden="true"
																				></span>
																				<span
																					className="spinner-grow spinner-grow-sm mr-2"
																					role="status"
																					aria-hidden="true"
																				></span>
																				<span
																					className="spinner-grow spinner-grow-sm mr-2"
																					role="status"
																					aria-hidden="true"
																				></span>
																			</>
																		)} */}
																		{isRegister && "Register"}
																		{isResetPassword && "Reset"}
																		{!isResetPassword && !isRegister && "Login"}
																	</button>
																</div>

																{!isRegister && !isResetPassword && (
																	<div className="mtop-15 text-center">
																		<span
																			className="text-primary btn"
																			onClick={() => setisResetPassword(true)}
																		>
																			<span>
																				<i className="mdi mdi-lock mr-1"></i>
																				Forgot your password?
																			</span>
																		</span>
																	</div>
																)}

																{isRegister && (
																	<div className="mtop-15 text-center">
																		<div className="log-in-page-footer">
																			<span>
																				By registering you agree to the
																				<span className="font-weight-medium text-primary btn">
																					<Link to="/terms-and-conditions">
																						Terms of Use.
																					</Link>
																				</span>
																			</span>
																		</div>
																	</div>
																)}

																<div className="text-center">
																	<div className="log-in-page-footer">
																		<span>
																			{isResetPassword
																				? "Remember it ?"
																				: isRegister
																				? "Already have an account ?"
																				: "Don't have an account ?"}
																			<span
																				onClick={loginToogleHandler}
																				className="font-weight-medium text-primary btn"
																			>
																				{isRegister || isResetPassword
																					? "Login"
																					: "Register Now"}
																			</span>
																		</span>
																	</div>
																</div>
															</form>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{isRegister && (
									<div className="col-lg-4 col-md-5 col-sm-6 col-xs-12  pull-right sidebar">
										<div className="widget">
											<h3>Why to have an account in JobMagic? </h3>
											<ul>
												<li>
													<p>
														<i className="fa fa-clock-o"></i>Fast applying for
														offers the necessary documents are always at hand
													</p>
												</li>
												<li>
													<p>
														<i className="fa fa-child"></i>Effectively target
														employers You can share your profile and CV
														recruiters
													</p>
												</li>

												<li>
													<p>
														<i className="fa fa-check-circle-o"></i>Matching
														jobs We will suggest you offer to fit your needs on
														e-mail
													</p>
												</li>
											</ul>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
