import React from "react";

import NavBar from "./Navbar";

function Header(props) {
	return (
		<div id="header" className="container-fluid home">
			<div className="row">
				<NavBar />
			</div>
		</div>
	);
}

export default Header;
