import { Api } from "../Api";

// Get Job Employer for home page

// export const getEmployerData = (response) =>
// 	Api({
// 		url: "/Accounts/get-employer-photos",
// 		method: "GET",
// 		data: response,
// 	});
// Get Job Metadata
export const GET_EMPLOYER_DATA_SUCCESS = "GET_EMPLOYER_DATA_SUCCESS";
export const GET_EMPLOYER_DATA_FAIL = "GET_EMPLOYER_DATA_FAIL";

export const getEmployerData = (response) =>
	Api({
		url: "/Accounts/get-employer-photos",
		data: response,
		method: "GET",
		onSuccess: onGetEmployerDataSuccess,
	});
const onGetEmployerDataSuccess = (response) => ({
	type: GET_EMPLOYER_DATA_SUCCESS,
	data: response,
});
