import { Api } from "../Api";

// Get Jobs
export const GET_JOBS_INIT = "GET_JOBS_INIT";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_FAIL = "GET_JOBS_FAIL";

export const getJobs = (params) =>
	Api({
		url: "/Jobs",
		method: "GET",
		data: params,
		onInit: onGetJobsInit,
		onSuccess: onGetJobsSuccess,
		onFailure: onGetJobsFail,
	});
const onGetJobsInit = () => ({
	type: GET_JOBS_INIT,
});
const onGetJobsSuccess = (response) => ({
	type: GET_JOBS_SUCCESS,
	data: response,
});
const onGetJobsFail = (response) => ({
	type: GET_JOBS_FAIL,
	data: response,
});

// Get Job Details
export const GET_JOB_DETAILS_INIT = "GET_JOB_DETAILS_INIT";
export const GET_JOB_DETAILS_SUCCESS = "GET_JOB_DETAILS_SUCCESS";
export const GET_JOB_DETAILS_FAIL = "GET_JOB_DETAILS_FAIL";
export const getJobDetails = (id) =>
	Api({
		url: `/Jobs/${id}`,
		method: "GET",
		onInit: onGetJobDetailsInit,
		onSuccess: onGetJobDetailsSuccess,
		onFailure: onGetJobDetailsFail,
	});
export const onGetJobDetailsInit = () => ({
	type: GET_JOB_DETAILS_INIT,
});
const onGetJobDetailsSuccess = (response) => ({
	type: GET_JOB_DETAILS_SUCCESS,
	data: response,
});
const onGetJobDetailsFail = (response) => ({
	type: GET_JOB_DETAILS_FAIL,
	data: response,
});

// Get Job Metadata
export const GET_JOB_META_SUCCESS = "GET_JOB_META_SUCCESS";
export const GET_JOB_META_FAIL = "GET_JOB_META_FAIL";

export const getJobMeta = (params) =>
	Api({
		url: "/Meta",
		data: params ? params : { NeedAll: true },
		method: "GET",
		onSuccess: onGetJobMetaSuccess,
	});
const onGetJobMetaSuccess = (response) => ({
	type: GET_JOB_META_SUCCESS,
	data: response,
});

//apply JOb
export const APPLY_JOB_SUCCESS = "APPLY_JOB_SUCCESS";
export const APPLY_JOB_FAIL = "APPLY_JOB_FAIL";
export const APPLY_JOB_INIT = "APPLY_JOB_INIT";

export const applyJob = (data) =>
	Api({
		url: "/ApplyJob",
		data: data,
		method: "POST",
		onSuccess: onJobApplySuccess,
		onInit: onJobApplyInit,
		onFailure: onJObApplyFail,
	});
const onJobApplySuccess = (response) => ({
	type: APPLY_JOB_SUCCESS,
	data: response,
});
const onJobApplyInit = () => ({
	type: APPLY_JOB_INIT,
	data: {},
});
const onJObApplyFail = (response) => ({
	type: APPLY_JOB_FAIL,
	data: response,
});

// My Applied Job
export const GET_APPLIED_JOB_INIT = "GET_APPLIED_JOB_INIT";
export const GET_APPLIED_JOB_SUCCESS = "GET_APPLIED_JOB_SUCCESS";
export const GET_APPLIED_JOB_FAIL = "GET_APPLIED_JOB_FAIL";

export const getAppliedJob = (id, params) =>
	Api({
		url: `/ApplyJob/getjobs/${id}`,
		method: "GET",
		data: params,
		onInit: onGetAppliedJobInit,
		onSuccess: onGetAppliedJobSuccess,
		onFailure: onGetAppliedJobFail,
	});
export const onGetAppliedJobInit = () => ({
	type: GET_APPLIED_JOB_INIT,
});
const onGetAppliedJobSuccess = (response) => ({
	type: GET_APPLIED_JOB_SUCCESS,
	data: response,
});
const onGetAppliedJobFail = (response) => ({
	type: GET_APPLIED_JOB_FAIL,
	data: response,
});
