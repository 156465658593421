import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
// Reducers
import AppReducer from "./Reducers/App";
import AuthReducer from "./Reducers/Auth";
import AccountReducer from "./Reducers/Account";
import JobReducer from "./Reducers/Job";
import HomeReducer from "./Reducers/Home";
// Cutom middlewares
import { ApiMiddleware } from "./Middlewares/api";

const RootReducer = combineReducers({
	app: AppReducer,
	auth: AuthReducer,
	account: AccountReducer,
	job: JobReducer,
	home: HomeReducer,
});

const composeEnhancers =
	(process.env.NODE_ENV === "development"
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		: null) || compose;

export const store = createStore(
	RootReducer,
	composeEnhancers(applyMiddleware(ApiMiddleware, thunk))
);
