import {
	SET_ALERT,
	UNSET_ALERT,
	TOGGLE_LANGUAGE,
	GET_JOB_ZIP_CODE_META_SUCCESS,
} from "../../Actions/App";
import { LOGOUT } from "../../Actions/Auth";

const initState = {
	alert: {
		open: false,
		message: "",
		type: null,
		onOk: () => 1,
		onCancel: () => 1,
	},
	profileDetails: "loading",
	lang: "en",
	zipCodeData: {},
};

const AppReducer = (state = initState, action) => {
	const { type, data } = action;
	switch (type) {
		// Golbal alert
		case SET_ALERT:
			return { ...state, alert: { ...data, open: true } };
		case UNSET_ALERT:
			return { ...state, alert: { ...initState.alert } };

		// Toggle Languate
		case TOGGLE_LANGUAGE:
			return { ...state, lang: state.lang === "en" ? "jp" : "en" };

		// Logout
		case LOGOUT:
			return { ...initState };
		//zipcode Data
		case GET_JOB_ZIP_CODE_META_SUCCESS:
			return { ...state, zipCodeData: data };

		default:
			return state;
	}
};

export default AppReducer;
