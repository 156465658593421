import React from "react";
import Loader from "../../../assets/loader";
import { useSelector } from "react-redux";

function AlertBox(props) {
  const { open, message, type, onOk, onCancel } = useSelector(
    (store) => store.app.alert
  );
  return (
    open && (
      <div
        className="swal2-container swal2-center swal2-shown"
        style={{ overflowY: "auto" }}
      >
        <div
          aria-labelledby="swal2-title"
          aria-describedby="swal2-content"
          className="swal2-popup swal2-modal swal2-shown"
          tabIndex="-1"
          role="dialog"
          aria-live="assertive"
          aria-modal="true"
          style={{ display: "flex" }}
        >
          <div className="swal2-header">
            {type === "success" && (
              <div
                className="swal2-icon swal2-success swal2-animate-success-icon"
                style={{ display: "flex" }}
              >
                <div
                  className="swal2-success-circular-line-left"
                  style={{ backgroundColor: "rgb(255, 255, 255)" }}
                ></div>
                <span className="swal2-success-line-tip"></span>
                <span className="swal2-success-line-long"></span>
                <div className="swal2-success-ring"></div>
                <div
                  className="swal2-success-fix"
                  style={{ backgroundColor: "rgb(255, 255, 255)" }}
                ></div>
                <div
                  className="swal2-success-circular-line-right"
                  style={{ backgroundColor: "rgb(255, 255, 255)" }}
                ></div>
              </div>
            )}

            {type === "confirm" && (
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              ></div>
            )}

            {type === "error" && (
              <div
                className="swal2-icon swal2-error"
                style={{ display: "flex" }}
              >
                <span className="swal2-x-mark">
                  <span className="swal2-x-mark-line-left"></span>
                  <span className="swal2-x-mark-line-right"></span>
                </span>
              </div>
            )}
            <h2
              className="swal2-title"
              id="swal2-title"
              style={{ display: "flex" }}
            >
              {message}
            </h2>
          </div>

          {type === "loading" && <Loader />}

          {type !== "loading" && (
            <div className="swal2-actions">
              {onOk && (
                <button
                  type="button"
                  className="swal2-confirm btn btn-primary ml-2 mt-2"
                  aria-label=""
                  style={{
                    display: "inline-block",
                  }}
                  onClick={onOk}
                >
                  {type === "confirm"
                    ? "Confirm"
                    : type === "success"
                    ? "Done"
                    : "OK"}
                </button>
              )}
              {onCancel && (
                <button
                  type="button"
                  className="sswal2-cancel btn btn-danger ml-2 mt-2"
                  aria-label=""
                  style={{
                    display: "inline-block",
                  }}
                  onClick={onCancel}
                >
                  Cancel
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default AlertBox;
