import React, { useCallback } from "react";
import Login from "../Login";

function LoginRegisterModel(props) {
	const { open, onClose, message } = props;

	const handleClose = useCallback(() => {
		onClose();
	}, [onClose]);
	return (
		<>
			<div className="">
				<div
					className={`modal fade ${open ? "in modal-up" : ""}`}
					id="myModal"
					role="dialog"
				>
					{/* <!-- Popup Model--> */}

					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-body">
								<button
									type="button"
									className="close"
									onClick={handleClose}
									data-dismiss="modal"
								>
									&times;
								</button>

								<Login displaymessage={message} onClose={handleClose} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default LoginRegisterModel;
