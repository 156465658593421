import axios from "axios";
import { refreshJWT } from "../Actions/Auth";

// the below line is for development. remove it  for prod.
axios.defaults.withCredentials = true;

export const ApiMiddleware =
	({ dispatch }) =>
	(next) =>
	(action) => {
		if (action.type === "API") {
			const { url, method, data, onInit, onSuccess, onFailure } = action;
			let requestConfig = {
				url: `https://jobportal.meerfuture.com${url}`,
				method: method,
				[method === "GET" ? "params" : "data"]: data,
				headers: {
					"Access-Control-Allow-Credentials": "*",
				},
			};

			const token = localStorage.getItem("token");
			if (token)
				requestConfig = {
					...requestConfig,
					headers: {
						...requestConfig.headers,
						Authorization: `Bearer ${token}`,
					},
				};

			if (onInit) dispatch(onInit());

			return axios
				.request(requestConfig)
				.then((res) => {
					if (onSuccess) dispatch(onSuccess(res.data));
					return res.data;
				})
				.catch((err) => {
					console.log(err);
					// Handling expired token
					if (err.response && err.response.status === 406) {
						dispatch(refreshJWT(action));
					}
					// Handling other errors
					else {
						const error = err.response ? err.response.data : err.data;
						if (onFailure) dispatch(onFailure(error));
						return Promise.reject(error);
					}
				});
		}
		next(action);
	};
